import cn from 'classnames';

import Anchor from '@/components/shared/Anchor/Anchor';
import useSignup from '@/hooks/useSignup';
import useGlobalNav from '@/hooks/navigation/global';

interface Props {
  darkBackground: boolean;
}

export default function MobileCtaLinklist({darkBackground}: Props) {
  const {signupUrl, signupName} = useSignup();
  const {signupText} = useGlobalNav();
  return (
    <li>
      <Anchor
        href={signupUrl}
        className={cn('mr-4 text-base underline', {
          'text-white': darkBackground,
          'text-black': !darkBackground,
        })}
        componentName={signupName}
      >
        {signupText}
      </Anchor>
    </li>
  );
}
