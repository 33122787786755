import type {SVGProps} from 'react';

const Sell = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <g clipPath="url(#white_circle_svg__a)">
      <path
        d="M12.75 21.5v4c0 .966 2.35 1.75 5.25 1.75s5.25-.784 5.25-1.75v-4m4-7c0 .966-2.35 1.75-5.25 1.75s-5.25-.784-5.25-1.75m10.5 0c0-.966-2.35-1.75-5.25-1.75s-5.25.784-5.25 1.75m10.5 0v4.25c0 .46-.533.88-1.405 1.192M16.75 14.5V17m6.5 4.25c0 .966-2.35 1.75-5.25 1.75s-5.25-.784-5.25-1.75S15.1 19.5 18 19.5s5.25.784 5.25 1.75Zm16-1.25c0 10.631-8.619 19.25-19.25 19.25S.75 30.631.75 20 9.369.75 20 .75 39.25 9.369 39.25 20Z"
        stroke="url(#sell_svg__a)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Sell;
