import type {SVGProps} from 'react';

const Build = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <g clipPath="url(#white_circle_svg__a)">
      <path
        d="M19 21.5v-3h-2.5v1a1 1 0 0 1-1 1H14a1 1 0 0 1-1-1V14a1 1 0 0 1 1-1h1.5a1 1 0 0 1 1 1v1h6.75s4.25 0 4.25 4.5c0 0-2.25-1-5-1v3m-3.5 0h3.5m-3.5 0v6m3.5-6v6M39.25 20c0 10.631-8.619 19.25-19.25 19.25S.75 30.631.75 20 9.369.75 20 .75 39.25 9.369 39.25 20Z"
        stroke="url(#start_svg__b)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Build;
