import type {SVGProps} from 'react';

export default function EditionsSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.25 21C40.25 31.6315 31.6315 40.25 21 40.25C10.3685 40.25 1.75 31.6315 1.75 21C1.75 10.3685 10.3685 1.75 21 1.75C31.6315 1.75 40.25 10.3685 40.25 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.341 24.6079L18.8363 26.9574"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.9609 14.7523C28.2811 15.4085 29.324 16.6069 29.7356 18.1429C30.1472 19.679 29.8432 21.2382 29.028 22.4667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.9769 20.7052C27.2556 20.1447 27.3388 19.4842 27.164 18.832C26.9893 18.1799 26.587 17.6495 26.0654 17.3034"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.5357 20.5604C14.4233 20.1409 14.5945 19.6968 14.9595 19.4613L21.4187 15.2941C21.9885 14.9265 22.7512 15.2206 22.9267 15.8756L24.9726 23.5109C25.1481 24.1659 24.6346 24.802 23.9574 24.7685L16.2799 24.3892C15.8461 24.3678 15.4758 24.0688 15.3633 23.6493L14.5357 20.5604Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12.0335 21.5081L12.7226 24.0796"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
