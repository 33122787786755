import cn from 'classnames';

import Anchor from '@/components/shared/Anchor/Anchor';
import Button from '@/components/base/elements/Button/Button';
import useSignup from '@/hooks/useSignup';
import useGlobalNav from '@/hooks/navigation/global';
import {useShowHeaderPdfDownload} from '@/components/brochureV2/hooks/useShowHeaderPdfDownload';
import useDataLayer from '@/hooks/useDataLayer';
interface Props {
  darkBackground: boolean;
}

export default function DesktopCtaLinklist({darkBackground}: Props) {
  const {loginUrl, loginText, signupText, headerPdfCta, headerPdfCtaLink} =
    useGlobalNav();
  const {signupUrl, signupName} = useSignup();
  const data = useDataLayer();
  const isDownloadDocumentCta = useShowHeaderPdfDownload(data.pageTopic);

  return (
    <>
      {isDownloadDocumentCta && (
        <li className="mr-8 text-base">
          <Anchor
            className={cn('whitespace-nowrap hover:underline', {
              'text-white': darkBackground,
              'text-black': !darkBackground,
            })}
            href={headerPdfCtaLink}
            componentName="InformationPDF"
            target="_blank"
          >
            {headerPdfCta}
          </Anchor>
        </li>
      )}
      <li className="mr-8 text-base">
        <Anchor
          className={cn('whitespace-nowrap hover:underline', {
            'text-white': darkBackground,
            'text-black': !darkBackground,
          })}
          href={loginUrl}
          componentName="login"
        >
          {loginText || 'Log in'}
        </Anchor>
      </li>
      <li className="leading-[0]">
        <Button
          href={signupUrl}
          mode={darkBackground ? 'dark' : 'light'}
          size="small"
          componentName={signupName}
          className="py-2 px-5 text-base font-bold tracking-[-0.01em] whitespace-nowrap"
        >
          {signupText || 'Start free trial'}
        </Button>
      </li>
    </>
  );
}
