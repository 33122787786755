import {localizePathBySite} from '@/utils/site';
import type {I18nRequestContext, NavigationListItem} from '@/types';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function getManageNav({t, site}: I18nRequestContext) {
  const navItems: NavigationListItem[] = [
    {
      content: t('nav:manageYourBusiness'),
      url: localizePathBySite('/manage', site),
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Manage your business',
      },
      description: t('nav:manageYourBusinessDescription'),
    },
  ];

  // Analytics
  navItems.push({
    content: t('nav:measureYourPerformance'),
    url: localizePathBySite('/analytics', site),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Measure your Performance',
    },
    description: t('nav:measureYourPerformanceDescription'),
  });

  if (site.features.includes('shipping')) {
    navItems.push({
      content: t('nav:shippingV2'),
      url: localizePathBySite('/shipping', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Ship orders faster',
      },
      description: t('nav:shippingDescriptionV2'),
    });
  }

  navItems.push({
    content: t('nav:inventoryOrderManagement'),
    url: localizePathBySite('/orders', site),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Organize inventory and orders',
    },
    description: t('nav:inventoryOrderManagementDescription'),
  });

  if (site.features.includes('fulfillment')) {
    navItems.push({
      content: t('nav:fulfillmentV2'),
      url: localizePathBySite('/fulfillment', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Organize inventory and orders',
      },
      description: t('nav:fulfillmentDescription'),
    });
  }

  if (site.features.includes('balance')) {
    navItems.push({
      content: t('nav:moneyManagement'),
      url: localizePathBySite('/balance', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Get paid faster',
      },
      description: t('nav:moneyManagementDescription'),
    });
  }

  if (site.features.includes('capital')) {
    navItems.push({
      content: t('nav:businessFunding'),
      url: localizePathBySite('/capital', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Secure business funding',
      },
      description: t('nav:businessFundingDescription'),
    });
  }

  const flowAllowListLocales: string[] = [
    'en',
    'da',
    'nl',
    'sv',
    'nl-BE',
    'en-BE',
    'de-BE',
    'fr-BE',
    'en-DK',
    'en-NO',
    'en-ID',
    'en-PH',
    'en-ZA',
    'en-MY',
    'en-NG',
    'es',
    'es-CO',
    'es-MX',
    'ko',
    'vi',
    'en-HK',
    'zh-TW',
  ];

  if (flowAllowListLocales.includes(site.locale)) {
    navItems.push({
      content: t('nav:flowV2'),
      url: localizePathBySite('/flow', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Automate your business',
      },
      description: t('nav:flowDescription'),
    });
  }

  return {
    content: t('nav:manage'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Manage Nav',
    },
    columnStart: 10,
    columnSpan: 3,
    icon: 'manage',
    navItems,
  };
}
