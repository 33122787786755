import type {SVGProps} from 'react';

// NOTE: For this icon to appear correctly in your component, you must also import and use `./GradientDefs`.

const Manage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <g clipPath="url(#white_circle_svg__a)">
      <path
        d="M12.75 15.75c0 1.243.75 2.5 2.25 2.5s2.5-1.257 2.5-2.5c0 1.243 1 2.5 2.5 2.5s2.5-1.257 2.5-2.5c0 1.243 1 2.5 2.5 2.5s2.25-1.257 2.25-2.5m-12.5 11.5h10.5a2 2 0 0 0 2-2v-9.067a2 2 0 0 0-.179-.827l-.538-1.184A2 2 0 0 0 24.713 13h-9.425a2 2 0 0 0-1.82 1.172l-.538 1.184a2 2 0 0 0-.18.827v9.067a2 2 0 0 0 2 2Zm3-3.5a2 2 0 0 1 2-2h.5a2 2 0 0 1 2 2v3.5h-4.5v-3.5ZM39.25 20c0 10.631-8.619 19.25-19.25 19.25S.75 30.631.75 20 9.369.75 20 .75 39.25 9.369 39.25 20Z"
        stroke="url(#manage_svg__a)"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default Manage;
