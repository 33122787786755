import type {SVGProps} from 'react';

// NOTE: For this icon to appear correctly in your component, you must also import and use `./GradientDefs`.

const HelpAndSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <g clipPath="url(#help-and-support_svg__a)">
      <circle
        cx={20}
        cy={20}
        r={19.25}
        stroke="currentColor"
        strokeWidth={1.5}
      />
      <path
        d="M20 20v6"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 16.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default HelpAndSupport;
