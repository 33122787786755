import type {I18nRequestContext} from '@/types';

import {MAIN_NAV_GA_EVENT} from '../constants';

import {getHelpNav} from './getHelpNav';
import {getTopicsNav} from './getTopicsNav';
import {getToolsNav} from './getToolsNav';

export function getResourcesNav(i18nRequestContext: I18nRequestContext) {
  const {t} = i18nRequestContext;
  return {
    content: t('nav:resources'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Resources Nav',
    },
    navItems: [
      getHelpNav(i18nRequestContext),
      getTopicsNav(i18nRequestContext),
      getToolsNav(i18nRequestContext),
    ],
  };
}
