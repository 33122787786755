import type {SVGProps} from 'react';

// NOTE: For this icon to appear correctly in your component, you must also import and use `./GradientDefs`.

const Market = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <g clipPath="url(#white_circle_svg__a)">
      <path
        d="m12.75 19.25 5.5-5.5m1 2.5v-3.5h-3.5m-2 14.5h.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1Zm6 0h.5a1 1 0 0 0 1-1v-5.5a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1v5.5a1 1 0 0 0 1 1Zm6 0h.5a1 1 0 0 0 1-1v-12.5a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1v12.5a1 1 0 0 0 1 1ZM39.25 20c0 10.631-8.619 19.25-19.25 19.25S.75 30.631.75 20 9.369.75 20 .75 39.25 9.369 39.25 20Z"
        stroke="url(#market_svg__a)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Market;
