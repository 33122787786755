import Button, {
  type ButtonProps,
} from '@/components/base/elements/Button/Button';
import {twMerge} from '@/stylesheets/twMerge';

import {EditionsLogoLight} from './svg/EditionsLogo';

export function EditionsButton({children, href, mode}: ButtonProps) {
  const isTextTooLong = typeof children === 'string' && children.length > 12;

  return (
    <Button
      intent="primary"
      mode={mode}
      href={href}
      size="small"
      border={false}
      className={twMerge(
        'bg-[#00DEE3] text-black hover:bg-[#00F2F7] hover:text-black active:text-black active:bg-[#00B6BA] focus:bg-[#00DEE3] focus:border-[#6B24E0] focus:text-black p-2 pr-5',
        isTextTooLong && 'nav:pr-2 min-[1300px]:pr-5',
      )}
    >
      <div className="flex grow w-full h-full items-center gap-x-2">
        <EditionsLogoLight />
        <span
          className={twMerge(
            isTextTooLong && 'nav:hidden min-[1300px]:inline',
            mode === 'dark' && '!text-black',
          )}
        >
          {children}
        </span>
      </div>
    </Button>
  );
}
