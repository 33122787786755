import type {I18nRequestContext} from '@/types';
import {localizePathBySite} from '@/utils/site';
import {
  appLocalizedUrl,
  themesUrl,
  isToolsEnabled,
} from '@/hooks/navigation/utils';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function getStartNav({t, site}: I18nRequestContext) {
  const toolsPath = () => {
    switch (site.locale) {
      case 'es-ES':
        return '/es/tools';
      case 'fr-CA':
      case 'fr-BE':
        return '/fr/tools';
      default:
        return localizePathBySite('/tools', site);
    }
  };
  const navItems = [];

  navItems.push(
    {
      content: t('nav:startYourBusiness'),
      url: localizePathBySite('/start', site),
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Start',
      },
      description: t('nav:startYourBusinessDescription'),
    },
    {
      content: t('nav:ecommerceV2'),
      url: localizePathBySite('/website/builder', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Create your website',
      },
      description: t('nav:ecommerceDescription'),
    },
    {
      content: t('nav:storeThemesDescription'),
      url: themesUrl(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Customize your store',
      },
      description: t('nav:storeThemes'),
    },
    {
      content: t('nav:appStore'),
      url: appLocalizedUrl(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Find business apps',
      },
      description: t('nav:appStoreDescription'),
    },
    {
      content: t('nav:domainsAndHosting'),
      url: localizePathBySite('/domains', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Own your site domain',
      },
      description: t('nav:domainsAndHostingDescription'),
    },
  );

  if (isToolsEnabled(site)) {
    navItems.push({
      content: t('nav:freeBusinessTools'),
      url: toolsPath(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Explore free business tools',
      },
      description: t('nav:freeBusinessToolsDescription'),
    });
  }

  return {
    content: t('nav:start'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Build Nav',
    },
    columnStart: 1,
    columnSpan: 3,
    icon: 'build',
    navItems,
  };
}
