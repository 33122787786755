import type {SVGProps} from 'react';

const PopularTopics = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <g
      clipPath="url(#white_circle_svg__a)"
      stroke="currentColor"
      strokeWidth={1.5}
    >
      <circle cx={20} cy={20} r={19.25} />
      <path
        d="m22.023 15.04 3 3M12.75 27.25l4.25-1 9.95-9.95a1 1 0 0 0 0-1.413l-1.837-1.836a1 1 0 0 0-1.414 0L13.75 23l-1 4.25Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default PopularTopics;
