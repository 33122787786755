import type {SVGProps} from 'react';

export default function EditionsSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_213_2183)">
        <path
          d="M40 21C40 31.6315 31.3815 40.25 20.75 40.25C10.1185 40.25 1.5 31.6315 1.5 21C1.5 10.3685 10.1185 1.75 20.75 1.75C31.3815 1.75 40 10.3685 40 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27 21L18 21"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M22 24L18 24"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M18 16C18 15.4477 18.4477 15 19 15H26C26.5523 15 27 15.4477 27 16V17C27 17.5523 26.5523 18 26 18H19C18.4477 18 18 17.5523 18 17V16Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M15 14C15 12.8954 15.8954 12 17 12H28C29.1046 12 30 12.8954 30 14V25C30 26.1046 29.1046 27 28 27H17C15.8954 27 15 26.1046 15 25V14Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M14 16V16C12.8954 16 12 16.8954 12 18V28C12 29.1046 12.8954 30 14 30H24.5C25.6046 30 26.5 29.1046 26.5 28V28"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_213_2183">
          <rect
            width="40.5"
            height="40.5"
            fill="white"
            transform="translate(0.5 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
