import type {SVGProps} from 'react';

// NOTE: For this icon to appear correctly in your component, you must also import and use `./GradientDefs`.

const EssentialTools = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <g
      clipPath="url(#white_circle_svg__a)"
      stroke="currentColor"
      strokeWidth={1.5}
    >
      <circle cx={20} cy={20} r={19.25} />
      <path d="M16.75 16.75v-2a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2v2m-2 4v1.5a1 1 0 0 1-1 1h-.5a1 1 0 0 1-1-1v-1.5m2.5 0a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1m2.5 0h6m-8.5 0h-6m2 6.5h10.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2h-10.5a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2Z" />
    </g>
  </svg>
);

export default EssentialTools;
