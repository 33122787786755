import cn from 'classnames';

import Build from './Build';
import Sell from './Sell';
import Market from './Market';
import Manage from './Manage';
import HelpAndSupport from './HelpAndSupport';
import PopularTopics from './PopularTopics';
import EssentialTools from './EssentialTools';
import Changelog from './Changelog';
import Editions from './Editions';
import AllEditions from './AllEditions';
import Newsroom from './Newsroom';

export function getIcon(icon: string) {
  const sharedClasses = '-ml-2 nav:ml-0 mr-3 md:mr-3 inline-block h-10 w-10';

  switch (icon) {
    case 'build':
      return (
        <span aria-hidden="true" className={sharedClasses}>
          <Build />
        </span>
      );
    case 'sell':
      return (
        <span aria-hidden="true" className={sharedClasses}>
          <Sell />
        </span>
      );
    case 'market':
      return (
        <span aria-hidden="true" className={sharedClasses}>
          <Market />
        </span>
      );
    case 'manage':
      return (
        <span aria-hidden="true" className={sharedClasses}>
          <Manage />
        </span>
      );
    case 'helpAndSupport':
      return (
        <span aria-hidden="true" className={cn(sharedClasses)}>
          <HelpAndSupport />
        </span>
      );
    case 'popularTopics':
      return (
        <span aria-hidden="true" className={cn(sharedClasses)}>
          <PopularTopics />
        </span>
      );
    case 'essentialTools':
      return (
        <span aria-hidden="true" className={cn(sharedClasses)}>
          <EssentialTools />
        </span>
      );
    case 'changelog':
      return (
        <span aria-hidden="true" className={cn(sharedClasses)}>
          <Changelog />
        </span>
      );
    case 'editions':
      return (
        <span aria-hidden="true" className={cn(sharedClasses)}>
          <Editions />
        </span>
      );
    case 'allEditions':
      return (
        <span aria-hidden="true" className={cn(sharedClasses)}>
          <AllEditions />
        </span>
      );
    case 'newsroom':
      return (
        <span aria-hidden="true" className={cn(sharedClasses)}>
          <Newsroom />
        </span>
      );
  }
}
