import type {SVGProps} from 'react';

export default function EditionsSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_213_1927)">
        <path
          d="M39.75 21C39.75 31.6315 31.1315 40.25 20.5 40.25C9.86852 40.25 1.25 31.6315 1.25 21C1.25 10.3685 9.86852 1.75 20.5 1.75C31.1315 1.75 39.75 10.3685 39.75 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 22L16 22"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M20 25L16 25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M16 17C16 16.4477 16.4477 16 17 16H24C24.5523 16 25 16.4477 25 17V18C25 18.5523 24.5523 19 24 19H17C16.4477 19 16 18.5523 16 18V17Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <rect
          x="13"
          y="13"
          width="15"
          height="15"
          rx="2"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_213_1927">
          <rect
            width="40.5"
            height="40.5"
            fill="white"
            transform="translate(0.25 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
