import type {I18nRequestContext} from '@/types';

import {MAIN_NAV_GA_EVENT} from '../constants';

import {getStartNav} from './getStartNav';
import {getSellNav} from './getSellNav';
import {getMarketNav} from './getMarketNav';
import {getManageNav} from './getManageNav';
import {getSolutionsAsideNav} from './getSolutionsAsideNav';

export function getSolutionsNav(i18nRequestContext: I18nRequestContext) {
  const {t} = i18nRequestContext;
  return {
    content: t('nav:solutions'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Online Solutions',
    },
    navItems: [
      getStartNav(i18nRequestContext),
      getSellNav(i18nRequestContext),
      getMarketNav(i18nRequestContext),
      getManageNav(i18nRequestContext),
      getSolutionsAsideNav(i18nRequestContext),
    ],
  };
}
