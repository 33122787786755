import type {I18nRequestContext, NavigationListItem} from '@/types';
import {isShopPayAvailable} from '@/hooks/navigation/utils';
import {localizePathBySite} from '@/utils/site';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function getSellNav({t, site}: I18nRequestContext) {
  const b2bPath = () => {
    switch (site.locale) {
      case 'pt-BR':
      case 'da':
      case 'nl':
      case 'nl-BE':
      case 'en-BE':
      case 'en-NO':
      case 'sv':
      case 'vi':
      case 'ko':
      case 'zh-TW':
      case 'zh-HK':
        return '/plus/solutions/b2b-ecommerce';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return '/es-es/plus/soluciones/ecommerce-b2b';
      case 'fr-CA':
      case 'fr-BE':
        return '/fr/plus/solutions/ecommerce-b2b';
      case 'de-BE':
        return '/de/plus/losungen/b2b-ecommerce';
      default:
        return localizePathBySite('/plus/solutions/b2b-ecommerce', site);
    }
  };

  const navItems: NavigationListItem[] = [
    {
      content: t('nav:sellYourProducts'),
      url: localizePathBySite('/sell', site),
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell your products',
      },
      description: t('nav:sellYourProductsDescription'),
    },
  ];

  if (isShopPayAvailable(site)) {
    navItems.push({
      content: t('nav:checkoutV2'),
      url: localizePathBySite('/checkout', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Checkout customers',
      },
      description: t('nav:checkoutDescriptionV2'),
    });
  }

  navItems.push(
    {
      content: t('nav:sellOnline'),
      url: localizePathBySite('/online', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell online',
      },
      description: t('nav:sellOnlineDescription'),
    },
    {
      content: t('nav:salesChannelsV2'),
      url: localizePathBySite('/channels', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell on across channels',
      },
      description: t('nav:salesChannelsDescription'),
    },
  );

  if (site.features.includes('pos')) {
    navItems.push({
      content: t('nav:pointOfSaleV2'),
      url: localizePathBySite('/pos', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell in person',
      },
      description: t('nav:pointOfSaleDescription'),
    });
  }

  navItems.push(
    {
      content: t('nav:internationalSalesDescription'),
      url: localizePathBySite('/markets', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell globally',
      },
      description: t('nav:internationalSales'),
    },
    {
      content: t('nav:b2b'),
      url: b2bPath(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell wholesale and direct',
      },
      description: t('nav:b2bDescription'),
    },
  );

  if (site.features.includes('payments_online')) {
    navItems.push({
      content: t('nav:paymentsV2'),
      url: localizePathBySite('/payments', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Accept online payments',
      },
      description: t('nav:paymentsDescription'),
    });
  }

  return {
    content: t('nav:sell'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Sell Nav',
    },
    columnStart: 4,
    columnSpan: 3,
    icon: 'sell',
    navItems,
  };
}
