import type {SVGProps} from 'react';

export default function ChangelogSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_213_1921)">
        <path
          d="M39.5 21C39.5 31.6315 30.8815 40.25 20.25 40.25C9.61852 40.25 1 31.6315 1 21C1 10.3685 9.61852 1.75 20.25 1.75C30.8815 1.75 39.5 10.3685 39.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="13"
          y="14"
          width="15"
          height="14"
          rx="2"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M25 24L19 24"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M25 21L19 21"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M25 18L19 18"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M17 18L16 18"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M17 21L16 21"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M17 24L16 24"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_213_1921">
          <rect
            width="40.5"
            height="40.5"
            fill="white"
            transform="translate(0 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
