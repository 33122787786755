import {useMatches} from '@remix-run/react';
import {useMemo, type PropsWithChildren} from 'react';
import cn from 'classnames';

import type {Theme} from '@/enums';
import SkipLink from '@/components/shared/Page/SkipLink';
import useTheme from '@/hooks/useTheme';
import useHandle from '@/hooks/useHandle';
import CookieNoticeBanner from '@/components/brochureV2/primitive/CookieNoticeBanner/CookieNoticeBanner';
import DefaultHeader, {
  type HeaderProps,
} from '@/components/brochureV2/section/Header/Header';
import Footer from '@/components/brochureV2/section/Footer/Footer';

import {useTranslations} from './I18N';
import Wrapper from './Wrapper';

type PageLayoutProps = {
  header?: React.ComponentType<HeaderProps>;
  footer?: JSX.Element | null;
  title?: string;
  metaDescription?: string;
  isPureTitle?: boolean;
  hasScrollRestoration?: boolean;
};

// Option to remove skip link by theme
const THEME_TYPES_WITHOUT_SKIP_LINK: Theme[] = [];

export default function PageLayout({
  header = DefaultHeader,
  footer = <Footer />,
  title,
  metaDescription,
  children,
  isPureTitle,
  hasScrollRestoration = false,
}: PropsWithChildren<PageLayoutProps>) {
  const matches = useMatches();
  let data: Record<string, any> = {};
  for (const match of matches) {
    if (match.data) {
      data = {...data, ...match.data};
    }
  }
  const {t} = useTranslations();
  const [theme] = useTheme();
  const {mainClass, CustomModal} = useHandle();
  const {realCountryCode, rootDomain} = data;
  const shouldRenderSkipLink = !THEME_TYPES_WITHOUT_SKIP_LINK.includes(theme);

  const Header = useMemo(() => header, [header]);

  return (
    <Wrapper
      data={data}
      title={title}
      isPureTitle={isPureTitle}
      metaDescription={metaDescription}
      hasScrollRestoration={hasScrollRestoration}
    >
      {CustomModal && <CustomModal />}
      {shouldRenderSkipLink && (
        <SkipLink
          mode="dark"
          text={t('global:ariaLabels.header.skipToContent')}
        />
      )}
      <div className="relative">
        <Header
          mainNavData={data.mainNavData}
          secondaryNavData={data.secondaryNavData}
          {...data.headerProps}
        />
        <main
          role="main"
          id="main"
          tabIndex={-1}
          className={cn('focus-visible:outline-none', mainClass)}
        >
          {children}
        </main>
        {footer}
        <CookieNoticeBanner
          countryCode={realCountryCode}
          rootDomain={rootDomain}
        />
      </div>
    </Wrapper>
  );
}
