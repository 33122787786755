import Button from '@/components/base/elements/Button/Button';
import useGlobalNav from '@/hooks/navigation/global';
import useSignup from '@/hooks/useSignup';

interface Props {
  darkBackground: boolean;
}

export default function MobileMenuCtaLinklist({darkBackground}: Props) {
  const {loginText, loginUrl, signupText} = useGlobalNav();
  const {signupUrl, signupName} = useSignup();
  return (
    <>
      <li className="py-1 sm:grow sm:pt-2">
        <Button
          href={loginUrl}
          intent="secondary"
          className="w-full py-2 px-5 text-base font-bold"
          mode={darkBackground ? 'dark' : 'light'}
          componentName="login"
        >
          {loginText}
        </Button>
      </li>
      <li className="pt-1 sm:grow sm:pt-2">
        <Button
          href={signupUrl}
          className="w-full py-2 px-5 text-base font-bold"
          mode={darkBackground ? 'dark' : 'light'}
          componentName={signupName}
        >
          {signupText}
        </Button>
      </li>
    </>
  );
}
