import {useExperiment} from '@/hooks/useExperiment';
import {
  EXP_JAPAN_BROCHURE_DOWNLOAD,
  EXP_JP_HOME_PAGE_REDESIGN,
} from '@/experiments';
import {useSiteData} from '@/hooks/useSiteData';

export const LOCALES_FOR_EXPERIMENT = ['ja'];
export const PAGES_FOR_EXPERIMENT = [
  '',
  'blog',
  'pricing',
  'tools',
  'free-trial',
  'store-login',
  'payments',
  'case-studies',
  'starter',
  'resources',
  'online',
];

export const useShowHeaderPdfDownload = (
  pageTopic: string | undefined,
): boolean => {
  const {enPath, site} = useSiteData();

  const experimentJapanBrochureDownload = useExperiment(
    EXP_JAPAN_BROCHURE_DOWNLOAD,
  );
  const experimentJpHomePageRedesign = useExperiment(EXP_JP_HOME_PAGE_REDESIGN);

  const isFeaturedPdfDownloadCta =
    experimentJapanBrochureDownload === 'treatment' ||
    (experimentJapanBrochureDownload !== 'control' &&
      experimentJpHomePageRedesign === 'treatment' &&
      enPath === '/');

  const isHomePageWithProperLocale = pageTopic === '';

  if (
    (pageTopic && PAGES_FOR_EXPERIMENT.includes(pageTopic)) ||
    isHomePageWithProperLocale
  ) {
    const isLocaleUsedInExperiment = site.locale
      ? LOCALES_FOR_EXPERIMENT.includes(site.locale)
      : false;

    const isCtaShowedByExperiment =
      isFeaturedPdfDownloadCta && isLocaleUsedInExperiment;

    return isCtaShowedByExperiment;
  }

  return false;
};
