const GradientDefs = () => (
  <svg className="sr-only" aria-hidden="true">
    <defs>
      {/* Start Icon */}
      <linearGradient
        id="start_svg__a"
        x1={4}
        y1={2.5}
        x2={37.5}
        y2={36}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00E392" />
        <stop offset={1} stopColor="#00B4CD" />
      </linearGradient>

      {/* Manage Icon */}
      <linearGradient
        id="manage_svg__a"
        x1={1}
        y1={1}
        x2={39}
        y2={39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1CD9D9" />
        <stop offset={1} stopColor="#70D50E" />
      </linearGradient>

      {/* Market Icon */}
      <linearGradient
        id="market_svg__a"
        x1={1}
        y1={1}
        x2={39}
        y2={39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EA4EF2" />
        <stop offset={1} stopColor="#29C9FF" />
      </linearGradient>

      {/* Build Icon */}
      <linearGradient
        id="start_svg__b"
        x1={4}
        y1={2.5}
        x2={37.5}
        y2={36}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00E392" />
        <stop offset={1} stopColor="#00B4CD" />
      </linearGradient>

      {/* Sell Icon */}
      <linearGradient
        id="sell_svg__a"
        x1={1}
        y1={1}
        x2={39}
        y2={39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33EDE2" />
        <stop offset={1} stopColor="#6754FF" />
      </linearGradient>

      {/* Shared */}
      <clipPath id="white_circle_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
      <linearGradient
        id="paint0_linear_1_569"
        x1="6.12412"
        y1="7.32529"
        x2="16.6923"
        y2="17.7506"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.161933" stopColor="#27293B" />
        <stop offset="0.760531" stopColor="#27293B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_569"
        x1="18.4621"
        y1="6.91041"
        x2="12.5203"
        y2="17.2518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#27293B" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_41_589"
        x1="7.59808"
        y1="8.96"
        x2="28.4853"
        y2="30.2112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#32386C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_41_589"
        x1="18.4622"
        y1="6.91041"
        x2="12.7852"
        y2="12.9621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_1_568"
        x1="85.4375"
        y1="44"
        x2="-160.562"
        y2="-57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.829504" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1_568"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.4375 49) rotate(-96.3402) scale(54.3323 72.1138)"
      >
        <stop stopColor="#3C00BB" stopOpacity="0.72" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1_568"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(107.438 44) rotate(-15.5615) scale(82.0061 39.4114)"
      >
        <stop stopColor="#423EFF" stopOpacity="0.6" />
        <stop offset="0.754349" stopColor="#12C06D" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_1_568"
        x1="15.1241"
        y1="17.3253"
        x2="25.6923"
        y2="27.7506"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.161933" stopColor="#27293B" />
        <stop offset="0.760531" stopColor="#27293B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_568"
        x1="27.4621"
        y1="16.9104"
        x2="21.5203"
        y2="27.2518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#27293B" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_1_612"
        x1="85.4375"
        y1="44"
        x2="-160.562"
        y2="-57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.829504" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1_612"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.4375 49) rotate(-99.5942) scale(44.6242 90.7846)"
      >
        <stop stopColor="#3C00BB" stopOpacity="0.72" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1_612"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(107.438 44) rotate(-47.641) scale(98.7905 47.4778)"
      >
        <stop stopColor="#423EFF" stopOpacity="0.6" />
        <stop offset="0.754349" stopColor="#12C06D" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_1_612"
        x1="15.1242"
        y1="17.3253"
        x2="25.6923"
        y2="27.7506"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.161933" stopColor="#27293B" />
        <stop offset="0.760531" stopColor="#27293B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_612"
        x1="27.4621"
        y1="16.9104"
        x2="21.5203"
        y2="27.2518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#27293B" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_1_651"
        x1="85.4375"
        y1="44"
        x2="-160.562"
        y2="-57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.829504" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1_651"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.4375 49) rotate(-99.5942) scale(44.6242 90.7846)"
      >
        <stop stopColor="#3C00BB" stopOpacity="0.72" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1_651"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(107.438 44) rotate(-47.641) scale(98.7905 47.4778)"
      >
        <stop stopColor="#423EFF" stopOpacity="0.6" />
        <stop offset="0.754349" stopColor="#12C06D" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_1_651"
        x1="15.1242"
        y1="17.3253"
        x2="25.6923"
        y2="27.7506"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.161933" stopColor="#27293B" />
        <stop offset="0.760531" stopColor="#27293B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_651"
        x1="27.4621"
        y1="16.9104"
        x2="21.5203"
        y2="27.2518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#27293B" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_41_588"
        x1="-59.2497"
        y1="-1.6632e-07"
        x2="-32.4337"
        y2="99.4491"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000294443" stopColor="#15161B" />
        <stop offset="0.606057" stopColor="#1C1F31" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_41_588"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(146.438 64) rotate(-172.051) scale(224.154 105.307)"
      >
        <stop stopColor="#333FB2" />
        <stop offset="0.75924" stopColor="#27293B" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_41_588"
        x1="16.5981"
        y1="18.96"
        x2="37.4853"
        y2="40.2112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#32386C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_41_588"
        x1="27.4622"
        y1="16.9104"
        x2="21.7852"
        y2="22.9621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_41_625"
        x1="-59.2497"
        y1="-1.6632e-07"
        x2="-32.4337"
        y2="99.4491"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000294443" stopColor="#15161B" />
        <stop offset="0.606057" stopColor="#1C1F31" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_41_625"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(146.438 64) rotate(-172.051) scale(224.154 105.307)"
      >
        <stop stopColor="#333FB2" />
        <stop offset="0.75924" stopColor="#27293B" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_41_625"
        x1="16.5981"
        y1="18.96"
        x2="37.4853"
        y2="40.2112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#32386C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_41_625"
        x1="27.4622"
        y1="16.9104"
        x2="21.7852"
        y2="22.9621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_41_662"
        x1="-59.2497"
        y1="-1.6632e-07"
        x2="-32.4337"
        y2="99.4491"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000294443" stopColor="#15161B" />
        <stop offset="0.606057" stopColor="#1C1F31" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_41_662"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(146.438 64) rotate(-172.051) scale(224.154 105.307)"
      >
        <stop stopColor="#333FB2" />
        <stop offset="0.75924" stopColor="#27293B" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_41_662"
        x1="16.5981"
        y1="18.96"
        x2="37.4853"
        y2="40.2112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#32386C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_41_662"
        x1="27.4622"
        y1="16.9104"
        x2="21.7852"
        y2="22.9621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8B1EB" />
        <stop offset="1" stopColor="#4F58AA" />
      </linearGradient>
    </defs>
  </svg>
);

export default GradientDefs;
